@import 'ckeditor5/ckeditor5.css';
// General
html, body {
  height: 100%;
}

.work-sans {
  font-family: var(--mainFontFamily) !important;
}

.btn:first-child:active{
  border-color: transparent;
}

// Widget Editor
.mat-bottom-sheet-container.widget-bottom-sheet {
  background: var(--wsm-bottom-sheet-bg);
  color: var(--wsm-white);
  padding: 20px;
}

.mat-bottom-sheet-container-medium {
  max-width: calc(100vw - 40px) !important;
}

.mat-option-disabled {
  background-color: gray !important;
}


// Panel Class for full height and width spinner in dialogs
.full-spinner .mat-dialog-container {
  position: relative !important;
  height: inherit;
}

.wrap-label {
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}

.custom-sticky-top {
  background-color: var(--wsm-bg-body);
}

.light-theme .custom-sticky-top {
  box-shadow: -5px 0px 0px 0px var(--lotion), 5px 0px 0px 0px var(--lotion);
}

.mat-bottom-sheet-container {
  background: #{var(--wsm-bg-primary)};
  color: inherit;
  font-family: #{var(--mainFontFamily)};

  header {
    color: #{var(--wsm-white)};

    p {
      color: var(--silverChalice);
    }
  }
}

.new-feature-badge {
  background: var(--cyanCornflowerBlue);
  border-radius: 5px;
  color: white;
  font-size: 12px;
  padding: 0.05rem 0.2rem;
}

.wsm-w-50 {
  width: 50% !important;
}

.custom-reset-map-btn {
  background-color: #fff;
  border-radius: 3px;
  border: 2px solid #fff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, .3);
  color: rgb(102, 102, 102);
  cursor: pointer;
  font-family: Roboto, Arial, sans-serif;
  font-size: 18px;
  height: 40px;
  line-height: 38px;
  margin: 10px;
  padding: 0 5px;
  text-align: center;
  width: 40px;

  &:hover {
    color: #000000;
  }
}

@media (max-width: 767.98px) {
  .wsm-w-50 {
    width: 100% !important;
  }
}

/* Media Queries */

@media (max-width: 991.98px) {
  .mat-bottom-sheet-container.widget-bottom-sheet {
    min-width: 76vw;
  }
}

.mat-mdc-optgroup{
  .mat-mdc-optgroup-label{
    pointer-events: none!important;
    opacity: 0.7;
  }

  .mat-mdc-option.mdc-list-item{
    padding-left: 32px!important;
  }
}

.widget-editor-ckeditor {
  .ck .ck-sticky-panel .ck-sticky-panel__content_sticky {
    position: static !important;
  }

  .ck-content {
    max-height: 270px;
  }
}
